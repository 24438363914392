import React, { useState } from "react";
import { API_URL } from "../constants";

import { Modal, Button, Form } from "react-bootstrap";


function EditModal(props) {

  const [titleInput, setTitleInput] = useState(props.task.title);
  const [descriptionInput, setDescriptionInput] = useState(props.task.description);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Edit Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="ModalForm">
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={titleInput}
              onChange={(e) => titleInputHandleChange(setTitleInput, e)}
              placeholder="Enter title"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              value={descriptionInput}
              onChange={(e) => descriptionInputHandleChange(setDescriptionInput, e)}
              placeholder="Enter description"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
          </Button>
        <Button variant="primary" onClick={() => UpdateItem(props, titleInput, descriptionInput)}>
          Update Task
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

function titleInputHandleChange(setTitleInput, event) {
  setTitleInput(event.target.value);
};

function descriptionInputHandleChange(setDescriptionInput, event) {
  setDescriptionInput(event.target.value);
};

function UpdateItem(props, titleInput, descriptionInput) {
  let refreshList = props.refreshList;
  let hide = props.onHide;

  fetch(`${API_URL}/api/todo/`, {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json", authorization : "bearer " + props.token
    },
    body: JSON.stringify({
      taskId: props.task.taskId,
      title: titleInput,
      description: descriptionInput,
    }),
  })
    .then(() => refreshList())
    .then(() => hide());
};

export default EditModal;