import React from "react";
import { motion } from "framer-motion";

import "../circleLoader.css"

const spinTransition = {
    loop: Infinity,
    duration: 1, 
    ease: "linear"
}

export default function CircleLoader() {
  return (
    <div className="containerStyle">
      <motion.span className="circleStyle"
      animate={{rotate: 360}}
      transition={spinTransition} />
    </div>
  );
}
