import React from 'react';
import LoginButton from './components/loginButton';
import NavBar from './navBar';
import "./App.css";

function LoginPage() {
    return (
        <div className="Centered-title">
            <div>
                <NavBar />
            </div>
            <div>
                <h1>Get Stuff Done!</h1>
            </div>
        </div>
    )
}

export default LoginPage;