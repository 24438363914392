import React, { useState } from "react";
import { API_URL } from "../constants";
import EditModal from "./editModal";

import { Button, Col } from "react-bootstrap";
import { motion, AnimatePresence, DragControls } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

function Item(props) {

  const [completed, setCompleted] = useState(props.task.completed);
  const [showModal, setShowModal] = useState(false);

  // console.log(props);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={props.task.taskId}
        initial={{
          opacity: 0,
          y: -50,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          duration: 0.5,
          delay: 0.5,
        }}
      >
        <div className="User-list-item">
          <div className="List-Content">
            <div className="Check-list-section">
              <input
                className="Check-item"
                type="checkbox"
                checked={completed}
                onChange={() => setItemCompleted(props, completed, setCompleted)}
              ></input>
            </div>
            <div className="Text-list-section">
              <p
                className={
                  completed ? "Title-completed" : "Title-item"
                }
              >
                {props.task.title}
              </p>
              <p
                className={
                  completed ? "Description-completed" : "Description-item"
                }
              >
                {props.task.description}
              </p>
            </div>
            <div className="Button-list-section">
              <FontAwesomeIcon
                icon={faPencilAlt}
                className="List-edit-icon"
                onClick={() => setShowModal(true)}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className="List-delete-icon"
                onClick={() => removeItem(props)}
              />
            </div>
          </div>
        </div>

        <EditModal
          show={showModal}
          onHide={() => setShowModal(false)}
          task={props.task}
          refreshList={props.refreshList}
          token={props.token}
        />
      </motion.div>
    </AnimatePresence>
  )

  function setItemCompleted(props, completed, setCompleted) {
    // let refreshList = this.props.refreshList;

    fetch(
      `${API_URL}/api/todo/${props.task.taskId}/${!completed}`,
      {
        method: "put",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json", authorization : "bearer " + props.token
        },
      }
    ).then(setCompleted(!completed));
  };


  function removeItem(props) {
    props.onDelete(props.task);

    let refreshList = props.refreshList;

    fetch(`${API_URL}/api/todo/${props.task.taskId}`, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json", authorization : "bearer " + props.token
      },
    }).then((res) => refreshList());
  };
}

export default Item;