import React, { useState } from "react";
import { API_URL } from "../constants";

import { Form as Formy } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function Form(props) {

  const [titleInput, setTitleInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");

  return (
    <div>
      <Formy className="Form" onSubmit={e => {e.preventDefault(); }}>
        <Formy.Group>
          <Formy.Label>Title</Formy.Label>
          <Formy.Control
            type="text"
            value={titleInput}
            onChange={(e) => titleInputHandleChange(setTitleInput, e)}
            placeholder="Enter title"
          />
        </Formy.Group>
        <Formy.Group>
          <Formy.Label>Description</Formy.Label>
          <Formy.Control
            as="textarea"
            rows="3"
            value={descriptionInput}
            onChange={(e) => descriptionInputHandleChange(setDescriptionInput, e)}
            placeholder="Enter description"
          />
        </Formy.Group>
        <Formy.Group className="buttonWrapper">
          <Button className="Form-Post" onClick={() => post(props, titleInput, descriptionInput, setTitleInput, setDescriptionInput)}>
            Post
          </Button>
        </Formy.Group>
      </Formy>
    </div>
  );
}

function titleInputHandleChange(setTitleInput, event) {

  setTitleInput(event.target.value);
};

function descriptionInputHandleChange(setDescriptionInput, event) {
  setDescriptionInput(event.target.value);
};

function post (props, titleInput, descriptionInput, setTitleInput, setDescriptionInput) {

console.log("in post " + titleInput + descriptionInput);


  props.onComplete({
    completed: false,
    TaskId: 1000000,
    title: titleInput,
    description: descriptionInput,
  });
  console.log(props.token);
  let refreshList = props.refreshList;

  fetch(`${API_URL}/api/todo/post`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json", authorization : "bearer " + props.token
    },
    body: JSON.stringify({
      title: titleInput,
      description: descriptionInput
    }),
  })
    .then((res) => console.log(res.json()))
    .then(() => refreshList()).catch((error) => {
      console.log(error);
    })

  setTitleInput("");
  setDescriptionInput("");
}

export default Form;