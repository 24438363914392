import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Item from "./components/item";
import Form from "./components/form";
import NavBar from "./navBar";
import LoginPage from "./loginPage";
import CircleLoader from "./components/circleLoader";
import ProgressBar from "./components/progressBar";

import { API_URL } from "./constants";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { render } from "@testing-library/react";

function App() {
  const {
    isLoading,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();
  const [tasks, setTasks] = useState([]);
  const { isAuthenticated } = useAuth0();
  const [token, setToken] = useState();
  const [showLoadingCircle, setShowLoadingCircle] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: "https://todolist.com",
          scope: "all",
        });
        const response = await fetch(API_URL + "/api/todo/", {
          headers: {
            Authorization: "bearer " + token,
          },
        }).then(setToken(token));
        setTasks(await response.json());
        setShowLoadingCircle(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  if (isLoading) return <div>Loading...</div>;

  if (isAuthenticated)
    return (
      <div className="App">
        <div>
          <NavBar />
        </div>
        <div className="Main-content">
          <div>
            <Form
              token={token}
              onComplete={(task) => addToResult(tasks, setTasks, task)}
              refreshList={() => populateMessage(setTasks, token)}
            ></Form>
          </div>
          <div>
            {showLoadingCircle ? (
              <CircleLoader />
            ) : (
              list(tasks, setTasks, token)
            )}
          </div>
        </div>
      </div>
    );
  return (
    <div>
      <LoginPage />
    </div>
  );
}

function list(tasks, setTasks, token) {
  return (
    <div>
      {tasks.reverse().map(function (task) {
        return (
          <Item
            token={token}
            key={task.taskId}
            task={task}
            refreshList={() => populateMessage(setTasks, token)}
            onDelete={() => removeFromResult(tasks, setTasks, task)}
          />
        );
      })}
    </div>
  );
}

//Get all tasks
function populateMessage(setTasks, token) {
  console.log("APIUrl" + " " + API_URL + "/api/todo/");

  fetch(API_URL + "/api/todo/", {
    headers: { authorization: "bearer " + token },
  }).then((response) => {
    response.json().then((result) => {
      if (result) {
        setTasks(result);
      }
    });
  });
}

function addToResult(tasks, setTasks, task) {
  tasks.push(task);
  setTasks(tasks);
}

function removeFromResult(tasks, setTasks, task) {
  let removedInt = tasks.indexOf(task);
  tasks.splice(removedInt, 1);
  setTasks(tasks);
}

export default App;
