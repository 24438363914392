import React from 'react';
import LoginButton from './components/loginButton';
import LogoutButton from './components/logoutButton';

import { useAuth0 } from '@auth0/auth0-react';

import "./navBar.css";

function NavBar() {

    const {isAuthenticated } = useAuth0();

    return (
        <div className="NavBar">
            <ul>
                {!isAuthenticated && (<li><LoginButton /></li>)}
                {isAuthenticated && (<li><LogoutButton /></li>)}
            </ul>
        </div>
    )
}

export default NavBar;